import { Routes, Route } from 'react-router-dom'
import React from 'react';
import Home from './pages/Home'
import Static from './pages/Static'
import {ModalProvider} from "./context";
import {Helmet} from "react-helmet";

function App() {
  return (
      <>
          <Helmet>
              <title>Kinder Россия</title>
              <meta property="og:title" content="Kinder Россия"/>
          </Helmet>
          <ModalProvider>
              <div id="wrapper" className="wrapper">
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/legal-aspects" element={<Static />} />
                      <Route path="/politika-v-otnoshenii-faylov-cookie" element={<Static />} />
                      <Route path="/politika-v-otnoshenii-obrabotki-personalnykh-dannykh" element={<Static />} />
                      <Route path="/technical-requirements" element={<Static />} />
                  </Routes>
              </div>
          </ModalProvider>
      </>
  );
}

export default App;
